import React from "react";
import { useState, useEffect } from "react";

export default function PromptsCreator() {
  const [source, setSource] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [QAArray, setQAArray] = useState([]);
  const [startingLine, setStartingLine] = useState(1);
  const [step, setStep] = useState(0);
  const [formatted, setFormatted] = useState([]);

  /* Split lines and paragraphs utility */
  useEffect(() => {
    let output = [];
    const paragraphs = source.split("\n").filter((p) => p.trim() !== "");
    for (let i = 0; i < paragraphs.length; i++) {
      const words = paragraphs[i].split(" ");
      let sentence = "";
      let sentences = [];
      for (let i = 0; i < words.length; i++) {
        if (sentence.length + words[i].length < 100) {
          sentence += words[i] + " ";
        } else {
          sentences.push(sentence.trim());
          sentence = words[i] + " ";
        }
      }
      sentences.push(sentence.trim());
      output.push(sentences);
    }
    setFormatted(output);
  }, [source]);

  /* Format lines and paragraphs output functionality */
  const getFormatted = (paragraphs = []) => {
    let output = "";
    let lineCount = 1;
    formatted.forEach((p, p_index) => {
      if (paragraphs.length > 0 && !paragraphs.includes(p_index)) {
        lineCount += p.length;
      } else {
        output += `Paragraph ${p_index + 1} ${""}\n`;
        p.forEach((sentence, s_index) => {
          output += `Line ${lineCount + s_index}: ${sentence}\n`;
        });
        output += "\n";
        lineCount += p.length;
      }
    });
    return output;
  };
  return (
    <div className="p-4">
      <div className="flex flex-row gap-2 items-center">
        {" "}
        <h1>Prompts Creation System</h1>
        <button
          className="text-xs border rounded-md py-1 px-2"
          onClick={() => setStep(0)}
        >
          Formatter
        </button>
        <button
          className="text-xs border rounded-md py-1 px-2"
          onClick={() => setStep(1)}
        >
          QA to Array
        </button>
      </div>

      {/*Step 1 */}
      <div className={`flex flex-col ${step !== 0 && "hidden"}`}>
        <div className="flex flex-col my-2">
          <small>Raw Text Input Here</small>
          <div className="flex flex-row gap-2 my-2">
            <small>Starting Line:</small>
            <input
              className="border rounded-md"
              onChange={(e) => setStartingLine(e.target.value)}
            ></input>
          </div>
          <textarea
            className="p-2 h-80 w-full border rounded-md text-xs"
            onChange={(e) => setSource(e.target.value)}
          ></textarea>
        </div>

        <div className="flex flex-col my-2">
          <small>Result Text Here</small>
          <textarea
            className="p-2 h-80 w-full border rounded-md text-xs"
            value={getFormatted()}
          ></textarea>
        </div>
      </div>

      {/*Step 1 */}

      <div className={`flex flex-col ${step !== 1 && "hidden"}`}>
        <div className="flex flex-row my-2 gap-4 items-center">
          <div className="flex flex-col">
            <small>Question</small>
            <textarea
              className="p-2 h-20 w-full border rounded-md text-xs"
              onChange={(e) => setQuestion(e.target.value)}
              value={question}
            ></textarea>
          </div>{" "}
          <div className="flex flex-col">
            <small>Answer</small>
            <textarea
              className="p-2 h-20 w-full border rounded-md text-xs"
              onChange={(e) => setAnswer(e.target.value)}
              value={answer}
            ></textarea>
          </div>
          <button
            className="h-full border rounded-md p-4"
            onClick={() => {
              setQAArray([...QAArray, [question, answer]]);
              setAnswer("");
              setQuestion("");
              console.log(QAArray.toString());
            }}
          >
            ADD
          </button>
        </div>

        <div className="flex flex-col my-2">
          <small>Result Array Here</small>
          <textarea
            className="p-2 h-80 w-full border rounded-md text-xs"
            value={JSON.stringify(QAArray)}
          ></textarea>
          <button
            className="h-full border rounded-md p-4"
            onClick={() => setQAArray([])}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
}
