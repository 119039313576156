import React from "react";
import { useState, useEffect } from "react";
import { Badge } from "./components/UI";
import Loading from "./components/Loading";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/Tabs";
import MDEditor from "@uiw/react-md-editor";
import { PDFViewer } from "@react-pdf/renderer";
import { Page, Text, View, Document } from "@react-pdf/renderer";
import Markdown from "react-markdown";
import { rssFeeds, searchNews, searchWeb, readArticle } from "./ragUtil";

const prompts = [
  ["[DSE] Opinion", "c546psa29thgmnt", "v2"],
  ["[DSE] Main Idea", "2edgchwelxqtn8g", "v2"],
  ["[DSE] Genre", "7xymdem8xsdndk6", "v2"],
  ["[DSE] Open Cloze", "l7mm7g94moi4pbx", "v2"],
  ["[Notes] Markdown Vocabularies", "xpwdw34eoh9vwtr", "production"],
  ["[Notes] Markdown Sentence Patterns", "7aohngp7g8o21md", "production"],
  ["[DSE] Metaphor", "dxf5hdce8zxkr4e", "v1"],
  ["[DSE] Tone", "dx507notqx0loun", "v1"],
  ["[DSE] Genre", "jubj61tdmutrqkh", "v1"],
  ["[DSE] Referencing (Short)", "cacczr8od10m0qa", "v1"],
  ["[DSE] Referencing (MC)", "6hgzvs12jh7l78b", "v1"],
  ["[DSE] T/F/NG", "4i3024dr2xnrd0p", "v1"],
  ["[DSE] Irony", "smgvec9zcid4le0", "v1"],
  ["[DSE] Word Meaning", "9y7wd18ht3dknef", "v1"],
  ["[DSE] Main Idea", "39f8hofqp0hf1dv", "v1"],
  ["[DSE] Opinion", "w2gbs5427utdw32", "v1"],
  ["[DSE] Proofreading", "om8qll6szookpdh", "v1"],
  ["[DSE] Matching", "vykgcfzesqgd1ux", "v1"],
  ["[DSE] Open Cloze", "p3qxbzd5pk9w19x", "v1"],
  ["[DSE] Long Questions (WIP)", "9e4cjhrejdeho44", "wip"],
  ["[DSE] MC Questions (WIP)", "tta35ic4a8d2rca", "wip"],
];

export default function App({ pb }) {
  const [tab, setTab] = useState("1");
  const [source, setSource] = useState("");
  const [formatted, setFormatted] = useState([]);
  const [selectedPrompts, setSelectedPrompts] = useState([]);
  const [selectedParagraphs, setSelectedParagraphs] = useState([]);
  const [numQuestions, setNumQuestions] = useState(1);
  const [generated, setGenerated] = useState("");
  const [fontSize, setFontSize] = useState(14);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");

  const onTabChange = (value) => {
    setTab(value);
  };

  const getPromptColor = (version) => {
    switch (version) {
      case "v1":
        return "bg-gray-300";
      case "v2":
        return "bg-blue-300";
      case "v3":
        return "bg-light-blue";
      case "v4":
        return "bg-light-purple";
        case "wip":
          return "bg-red-300";
          case "production":
          return "bg-green-300";
      default:
        return "bg-gray-100";
    }
  };

  /* Split lines and paragraphs utility */
  useEffect(() => {
    let output = [];
    const paragraphs = source.split("\n").filter((p) => p.trim() !== "");
    for (let i = 0; i < paragraphs.length; i++) {
      const words = paragraphs[i].split(" ");
      let sentence = "";
      let sentences = [];
      for (let i = 0; i < words.length; i++) {
        if (sentence.length + words[i].length < 100) {
          sentence += words[i] + " ";
        } else {
          sentences.push(sentence.trim());
          sentence = words[i] + " ";
        }
      }
      sentences.push(sentence.trim());
      output.push(sentences);
    }
    setFormatted(output);
  }, [source]);

  /**
   * Format lines and paragraphs output functionality
   * forPrompt - if true, will include paragraph and line text
   */
  const getFormatted = (paragraphs = [], forPrompt = true) => {
    let output = "";
    let lineCount = 1;
    formatted.forEach((p, p_index) => {
      if (paragraphs.length > 0 && !paragraphs.includes(p_index)) {
        lineCount += p.length;
      } else {
        if (forPrompt) output += `Paragraph ${p_index + 1}\n`;
        p.forEach((sentence, s_index) => {
          if (forPrompt) {
            output += `Line ${lineCount + s_index}: ${sentence}\n`;
          } else {
            if (s_index === 0) {
              output += `${lineCount + s_index}    [${p_index + 1}] `;
              output += ` ${sentence}\n`;
            } else {
              output += `${lineCount + s_index}    ${sentence}\n`;
            }
          }
        });
        output += "\n";
        lineCount += p.length;
      }
    });
    return output;
  };

  /* Generate questions API call */
  const generateQuestions = async () => {
    setLoading(true);
    for (let i = 0; i < selectedPrompts.length; i++) {
      const p = selectedPrompts[i];
      setLoadingMsg(`${i + 1}/${selectedPrompts.length}`);
      try {
        const response = await pb.send("/api/generate/text", {
          body: {
            prompt_id: p,
            input: getFormatted(selectedParagraphs),
            num_questions: numQuestions,
          },
          method: "POST",
        });
        setGenerated((prev) => prev + response.output + "\n\n");
      } catch (error) {
        window.alert(error.message);
      }
    }
    setLoading(false);
    setLoadingMsg("");
  };

  /* Restructure generated questions API call */
  const restructureQA = () => {
    setLoading(true);
    pb.send("/api/generate/text", {
      body: {
        prompt_id: "u5nt2hnppnufuap",
        input: generated,
      },
      method: "POST",
    })
      .then((response) => {
        setLoading(false);
        setGenerated(response.output);
      })
      .catch((error) => {
        setLoading(false);
        window.alert(error.message);
      });
  };

  /* Restructure Input Passage API call */
  const restructureArticle = () => {
    setLoading(true);
    pb.send("/api/generate/text", {
      body: {
        prompt_id: "1vui962qfkrxntz",
        input: source,
      },
      method: "POST",
    })
      .then((response) => {
        setLoading(false);
        setSource(response.output);
      })
      .catch((error) => {
        setLoading(false);
        window.alert(error.message);
      });
  };

  /* Download as txt file */
  const downloadTxtFile = (text) => {
    const element = document.createElement("a");
    const file = new Blob([text], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "download.txt";
    document.body.appendChild(element);
    element.click();
  };

  /* Fetch Sources */
  const [url, setUrl] = useState("");
  const [keywords, setKeywords] = useState("");
  const [articles, setArticles] = useState([]);

  const newsSearchHandler = async () => {
    setLoading(true);
    try {
      const entries = await searchNews(keywords);
      setArticles(entries);
    } catch {
      window.alert("Failed news search");
    }
    setLoading(false);
  };

  const webSearchHandler = async () => {
    setLoading(true);
    try {
      const entries = await searchWeb(keywords);
      setArticles(entries);
    } catch {
      window.alert("Failed web search");
    }
    setLoading(false);
  };

  const readArticleHandler = async (url) => {
    setLoading(true);
    try {
      const article = await readArticle(url);
      setSource(article);
      setArticles([]);
    } catch {
      window.alert(
        "Failed article read: check if URL is correct and not behind firewall/paywall"
      );
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col md:flex-row gap-4 p-4 justify-center">
      {loading && <Loading message={loadingMsg} />}

      <Tabs
        defaultValue="1"
        value={tab}
        onValueChange={onTabChange}
        className="w-full max-w-4xl"
      >
        <TabsList className="grid w-full md:grid-cols-4 grid-cols-1 h-fit">
          <TabsTrigger value="1">📝 Article Input</TabsTrigger>
          <TabsTrigger value="2">⚙️ Select Parameters</TabsTrigger>
          <TabsTrigger value="3">🧠 Generated Results</TabsTrigger>
          <TabsTrigger value="4">📑 PDF Output</TabsTrigger>
        </TabsList>

        <TabsContent value="1" className="flex flex-col w-full items-center">
          <div className="flex flex-col max-w-[800px] w-full gap-4">
            <div className="flex flex-row items-center gap-2 mt-4">
              <Badge name="Step 1" />
              Find from sources or paste your article below:
            </div>

            <div className="flex flex-col md:flex-row gap-4 items-center pt-4 w-full">
              <input
                type="text"
                className="rounded-md border px-2 py-1 w-full outline-lizard"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
                placeholder="Keywords for web or news search"
                maxLength={100}
              />
              <button
                onClick={() => webSearchHandler()}
                class="btn-simple min-w-32"
                disabled={keywords === ""}
              >
                Web Search
              </button>
              <button
                onClick={() => newsSearchHandler()}
                class="btn-simple min-w-32"
                disabled={keywords === ""}
              >
                News Search
              </button>
            </div>

            <div className="text-xs text-gray-500">
              News Sources: {Object.keys(rssFeeds).join(", ")}
            </div>

            {articles.map((article, i) => (
              <div className="flex flex-row gap-2" key={i}>
                <a
                  href={article.url}
                  target="_blank"
                  className="w-full"
                  rel="noreferrer"
                >
                  <small>
                    <b>
                      {article.title}
                      {article.similarity &&
                        ` (${(article.similarity * 100).toFixed(0)}%)`}
                    </b>
                    <br />
                    {article.snippet}
                  </small>
                </a>
                <button
                  onClick={() =>
                    setUrl(article.url, readArticleHandler(article.url))
                  }
                  class="btn-simple"
                >
                  Select
                </button>
              </div>
            ))}

            <div className="flex flex-col md:flex-row gap-4 items-center pb-4 w-full">
              <input
                type="url"
                className="rounded-md border px-2 py-1 w-full outline-lizard"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Article URL"
              />
              <button
                onClick={() => readArticleHandler(url)}
                class="btn-simple min-w-32"
                disabled={url === ""}
              >
                Fetch URL
              </button>
            </div>

            <textarea
              style={{ fontSize: `${fontSize}px` }}
              className="border py-16 px-12 outline-lizard h-[400px] max-w-[800px] w-full"
              value={source}
              onFocus={(e) => e.target.select()}
              onChange={(e) => setSource(e.target.value)}
              placeholder="Paste your article here."
            />

            <div className="flex flex-row gap-1 items-center">
              <small>Font Size</small>
              <input
                type="range"
                className="text-xs border p-1 rounded-md w-20 range accent-lizard"
                min={10}
                max={20}
                value={fontSize}
                onChange={(e) => {
                  setFontSize(e.target.value);
                }}
              />
              <small>{fontSize}</small>
            </div>

            {source && (
              <div className="flex flex-row gap-4">
                <button
                  className="btn btn-primary"
                  onClick={() => restructureArticle()}
                >
                  Restructure Article with AI
                </button>
                <button
                  className="btn-simple"
                  onClick={() => downloadTxtFile(getFormatted())}
                >
                  Download Text File
                </button>
              </div>
            )}

            <div className="flex flex-row items-center gap-2 mt-4">
              <Badge name="Step 2" />
              Verify the formatted paragraphs and lines:
            </div>
            <textarea
              className="border py-16 px-12 rounded-md text-xs text-gray-500 h-[400px] max-w-[800px] w-full"
              value={getFormatted()}
              contentEditable="false"
              disabled="true"
              placeholder="Formatted paragraphs and lines will appear here."
            />
          </div>
        </TabsContent>

        <TabsContent value="2" className="flex flex-col w-full items-center">
          <div className="flex flex-col max-w-[800px] w-full gap-4">
            <div className="flex flex-row items-center gap-2 mt-4">
              <Badge name="Step 2" />
              Verify the formatted paragraphs and lines:
            </div>
            <textarea
              className="border p-2 rounded-md text-xs text-gray-500 h-[200px]"
              value={getFormatted()}
              contentEditable="false"
              disabled="true"
              placeholder="Formatted paragraphs and lines will appear here."
            />

            <div className="flex flex-row items-center gap-2">
              <Badge name="Step 3" />
              Select question types (multiple):
            </div>
            <div className={`flex flex-row items-center gap-4 border rounded-md p-4 flex-wrap`}>
              {prompts.map((p) => (
                <label
                  key={p[1]}
                  className={`${getPromptColor(
                    p[2]
                  )}  px-2 py-1 rounded-md text-xs`}
                >
                  <input
                    type="checkbox"
                    className="accent-lizard mr-1 h-2"
                    value={p[1]}
                    checked={selectedPrompts.includes(p[1])}
                    onChange={() => {
                      if (selectedPrompts.includes(p[1])) {
                        setSelectedPrompts((prev) =>
                          prev.filter((prompt) => prompt !== p[1])
                        );
                      } else {
                        setSelectedPrompts((prev) => [...prev, p[1]]);
                      }
                    }}
                  />
                  {p[0]} 
                </label>
              ))}
              <small className="text-xs text-gray-400">
                *Prompts updated on 2024-09-29<br/>
                *<text className="text-blue-500">Blue Chips</text> are v2 of the original prompts, <text className="text-green-500">Green Chips</text> are production ready<br/>
                *<text className="text-red-500">MC questions/Long Questions</text> are still developing in progress, please do not use them to generate QA pairs.
              </small>
            </div>

            <div className="flex flex-row items-center gap-2">
              <Badge name="Step 4" className="color-lizard" />
              Select paragraphs (multiple, whole article if none selected):
            </div>
            <div className="flex flex-row gap-4 border rounded-md p-4 flex-wrap">
              {formatted.map((p, i) => (
                <label
                  key={i}
                  className="bg-slate-100 px-2 py-1 rounded-md text-xs"
                >
                  <input
                    type="checkbox"
                    className="accent-lizard mr-1 h-2"
                    value={i}
                    checked={selectedParagraphs.includes(i)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedParagraphs((prev) => [...prev, i]);
                      } else {
                        setSelectedParagraphs((prev) =>
                          prev.filter((p) => p !== i)
                        );
                      }
                    }}
                  />
                  P{i + 1}
                </label>
              ))}
            </div>

            <div className="flex flex-row gap-4 items-center">
              <div className="flex flex-row  items-center gap-2">
                <Badge name="Step 5" />
                Number of questions (max 10):
              </div>
              <input
                type="number"
                className="border p-2 rounded-md text-xs"
                value={numQuestions}
                onChange={(e) => setNumQuestions(e.target.value)}
                min="1"
                max="10"
              />
            </div>
            <button
              onClick={() => {
                generateQuestions();
                onTabChange("3");
              }}
              class="btn btn-primary"
              disabled={source === "" || selectedPrompts.length === 0}
            >
              Generate
            </button>
          </div>
        </TabsContent>

        <TabsContent value="3" className="flex flex-col gap-2 items-center">
          <div className="flex flex-col max-w-[800px] w-full gap-4">
            <MDEditor
              value={generated}
              onChange={setGenerated}
              height="600px"
            />

            {generated && (
              <div className="flex flex-row gap-4">
                <button
                  className="btn btn-primary"
                  onClick={() => restructureQA()}
                >
                  Restructure Q&A with AI
                </button>
                <button
                  className="btn-simple"
                  onClick={() => downloadTxtFile(generated)}
                >
                  Download Text File
                </button>
              </div>
            )}
          </div>
        </TabsContent>

        <TabsContent value="4" className="flex flex-col w-full items-center">
          <div className="flex flex-col max-w-[800px] w-full gap-4">
            <PDFViewer height={1000}>
              <Document>
                <Page size="A4">
                  <View style={{ padding: 20, margin: 20 }}>
                    <Text style={{ fontSize: 15, marginBottom: 20 }}>
                      Source Article
                    </Text>
                    <Text style={{ fontSize: 10 }}>
                      {getFormatted([], false)}
                    </Text>
                  </View>
                </Page>
                <Page size="A4">
                  <View style={{ padding: 20, margin: 20 }}>
                    <Text style={{ fontSize: 10, tabSize: 4 }}>
                      {/*<MDEditor.Markdown source={generated} />*/}
                      {generated}
                    </Text>
                  </View>
                </Page>
              </Document>
            </PDFViewer>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
}
