import React from "react";
import ReactDOM from "react-dom/client";
import PocketBase from "pocketbase";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ThemeProvider } from "@material-tailwind/react";
import "./index.css";
import App from "./App";
import Login from "./Login";
import Navbar from "./components/Navbar";
import PromptsCreator from "./pages/PromptsCreator";

const pb = new PocketBase(process.env.REACT_APP_BACKEND_URL);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar pb={pb} />
                {pb.authStore.isValid ? <App pb={pb} /> : <Login pb={pb} />}
              </>
            }
          />
          <Route
            path="prompts"
            element={
              <>
                <Navbar pb={pb} />
                {pb.authStore.isValid ? <PromptsCreator /> : <Login pb={pb} />}
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
