import { useState } from "react";
import Loading from "./components/Loading";

export default function Login({ pb }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const login = async () => {
    setLoading(true);
    try {
      await pb.collection("users").authWithPassword(email, password);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      setLoading(false);
      window.alert(error.message);
    }
  };

  return (
    <div className="flex flex-col w-full items-center mt-60 p-4">
      {loading && <Loading />}
      <div className="flex flex-col gap-4 justify-center items-center border rounded-md w-96 p-4 py-8">
        <p className="text-xl">User Login</p>
        <input
          type="email"
          value={email}
          placeholder="Email / Username"
          className="rounded-md border p-3 w-full outline-lizard"
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              login();
            }
          }}
        />
        <input
          type="password"
          value={password}
          placeholder="Password"
          className="rounded-md border p-3 w-full outline-lizard"
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              login();
            }
          }}
        />
        <button className="btn btn-primary w-full" onClick={login}>
          Login
        </button>
      </div>
    </div>
  );
}
