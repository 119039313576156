import React from "react";

const Navbar = ({ pb }) => {
  const logout = async () => {
    await pb.authStore.clear();
    window.location.reload();
  };

  return (
    <nav className="flex flex-row justify-between items-center border-b p-4">
      <div className="flex flex-row gap-2 items-center">
        <img src="/logo.png" className="w-10 h-10" />
        <div className="flex flex-col">
          <div className="text-lg font-bold mb-[-5px]">Lizard Enterprise</div>
          <div className="text-sm">UpgradeHK</div>
        </div>
      </div>

      <div className="ml-10 flex items-baseline space-x-4">
        <a href="/prompts">
          <button className="btn-simple border-gray-900 border-0">
            Prompts Creator
          </button>
        </a>
        {pb.authStore.isValid ? (
          <button className="btn" onClick={logout}>
            Logout
          </button>
        ) : null}
      </div>
    </nav>
  );
};

export default Navbar;
